.textarea {
  width: 100%;
  border-radius: 9px;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
  padding: 10px;
  font-weight: 400;
  font-family: 'Gotham';
  font-size: 15px;
  line-height: 18px;
  color: #181818;
  letter-spacing: 0.02em;
  border: 0.5px solid grey;
  display: block;
}

.grey {
  background-color: #f0f5f7;
  box-shadow: none;
  &:focus {
    background-color: white;
  }
}
.large {
  height: 400px;
}
.small {
  height: 200px;
}
.gray {
  background-color: #f0f5f7;
}
.white {
  background-color: white;
}
.errorInput {
  border: 1px solid red;
}
.errorMessage {
  margin-top: 4px;
  color: red;
}
