.verificationContainer {
  background-color: #f3f4f6;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin: auto;

  h2 {
    color: #333;
    margin-bottom: 10px;
  }

  .text {
    color: #555;
    font-size: 0.9em;
    margin-bottom: 20px;

    span {
      color: #000080;
    }
  }

  .codeInputs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    input {
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: 1.5em;
      border: 1px solid #ccc;
      border-radius: 5px;
      transition: border-color 0.3s;
      margin: 0 3px;

      &:focus {
        outline: none;
        border-color: #000080;
      }
    }
  }

  .resend {
    color: #000080;
    text-decoration: none;
    font-weight: bold;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .verifyBtn {
    background-color: #000080;
    color: white;
    border: none;
    padding: 10px 0;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
    width: 100%;
    height: 60px;
    font-family: Gotham;
    font-weight: 500;
    letter-spacing: 4px;

    &:hover {
      background-color: lighten(#000080, 5%);
    }
  }
}
.error {
  color: red;
}
