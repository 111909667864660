.autocomplete {
  padding-left: 1vw;
  position: relative;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 6px 20px rgba(64, 79, 104, 0.11);
  border-radius: 10px;
  margin-right: 18px;
  display: flex;
  border: 0.5px solid grey;

  .iconText {
    display: flex;
    align-items: center;

    input {
      width: 35vw;
      // padding: 0 1vw;
      border: none;
      border-radius: 4px;
      outline: none;
      font-family: 'Gotham';
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 14px;
      background-color: transparent;
      letter-spacing: 1px;
    }
  }

  .list {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #f3f3f3;
    border-top: none;
    box-shadow: 0px 6px 20px rgba(64, 79, 104, 0.11);
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
    border-radius: 10px;
    z-index: 5;

    li {
      padding: 10px;
      cursor: pointer;
      text-align: left;
      border: 1px solid rgba(0, 0, 0, 0.07);

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.gray {
  background-color: #f0f5f7;
}
