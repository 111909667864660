.searchMain {
  display: flex;
  flex-direction: row;
  margin: 51px 150px;

  .searchLeft {
    width: 35%;
    display: flex;
  }

  .searchLeftCategory {
    background: #f5f7fc;
    width: 95%;
    padding: 41px 34px 41px 27px;

    .searchLeftCategoryText {
      font-family: 'Gordita';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      letter-spacing: 0.02em;
      color: #181818;
      margin: 7px;
    }

    .searchCategoryInput {
      background-color: white;
      box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
      border-radius: 9px;
      display: flex;
      align-items: center;
      padding: 16px 22px 15px 16px;
      margin-bottom: 36px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #181818;

      .searchCategoryInputText {
        flex: 1;
        margin-left: 11px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.04em;
        color: #181818;
      }
    }
    .salaryLimits {
      display: flex;
      .circle1 {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid black;
      }

      .line {
        border-bottom: 1px solid black;
        flex: 1;
      }

      .circle2 {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid black;
      }
    }
  }

  .searchRest {
    display: flex;
    flex-direction: column;
    flex: 1;

    .jobApplFilters {
      .jobApplFiltersText {
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        letter-spacing: 0.02em;
      }
    }
    .resultsNr {
      display: flex;
      margin-top: 21px;
      align-items: center;
      .show {
        flex: 1;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #181818;
      }
      .sortBy {
        display: flex;
        align-items: center;
        border-radius: 9px;
        padding: 16px 12px;
        background-color: #f5f7fc;
        .text {
          font-size: 14px;
          color: #181818;
          margin-right: 11px;
        }
      }
    }
  }
}

.matchMe {
  display: flex;
  white-space: nowrap;
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.02em;
  color: #181818;
  input {
    width: 10%;
  }
}

.category {
  margin-top: 20px;
}
