.formLayout {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dialogPreferencesModal {
  width: 40%;
}
.red {
  color: red;
}
.header {
  margin-bottom: 5px;
}
input {
  border: 0;
  background-color: #f0f5f7;
  width: 100%;
}
input:focus {
  outline: none;
}
.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0;
  border-radius: 9px;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
  padding: 0 10px;
  font-weight: 400;
  font-family: 'Gotham';
  font-size: 15px;
  line-height: 18px;
  color: #181818;
  letter-spacing: 0.02em;
  border: 0.5px solid grey;
  background-color: #f0f5f7;
  box-shadow: none;
  height: 40px;
  &:focus {
    background-color: white;
  }
}

.selected {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  background: #dde7ff;
  border-radius: 9px;
  margin-right: 1vh;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #181818;
  margin-top: 2px;
  min-width: 30%;

  p {
    flex: 1;
    margin: 0 10px 0 0;
  }
}
.clearEmpty {
  flex: 1;
}

.buttonInput {
  background: none;
  color: #02029b;
  font-size: 20px;
  font-weight: 400;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}
.selectedJobTitles {
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
