.home {
  background-color: #ecf1ff;
  display: flex;
  height: 100%;
  padding-left: 10%;
}

.searchContainer {
  display: flex;
  align-items: center;
}

.person {
  width: 52%;
  background-image: url('../../assets/person.png');
  background-size: cover;
  margin-left: 56%;
}

.orange {
  color: #f38b08;
  font-weight: 700;
  font-family: 'Gotham';
  font-style: normal;
  font-size: 50px;
  line-height: 60px;
}
.ad {
  font-family: 'Gotham';
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #181818;
}
.font {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 15px;
  color: #181818;
  b {
    font-family: Gotham;
    font-weight: 400;
    font-size: 16px;
  }
}
.action {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 5%;
}
