.frame {
  width: 380px;
  background-color: #f5f7fc;
  padding: 41px 34px 21px 27px;
  display: flex;
  flex-direction: column;
  row-gap: 23px;
  border-radius: 7px;
  .progressBar {
    height: 13px;
    background-color: #d3d9e9;
    border-radius: 8px;
  }

  .progress1 {
    height: 100%;
    border-radius: 8px;
    width: 25%;
    background-image: linear-gradient(to right, #000080, #8445d5);
  }

  .progress2 {
    height: 100%;
    border-radius: 8px;
    width: 50%;
    background-image: linear-gradient(to right, #000080, #8445d5);
  }

  .progress3 {
    height: 100%;
    border-radius: 8px;
    width: 65%;
    background-image: linear-gradient(to right, #000080, #8445d5);
  }

  .resume {
    display: flex;
    flex-direction: column;
    flex-basis: fit-content;
    flex: 1;
  }

  .contactTitle {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.02em;
    color: #181818;
  }

  .contactDetails {
    p {
      margin-top: 0;
      .span {
        color: #898a8c;
      }
    }
    .input {
      height: 40px;
      width: 100%;
      border: 0;
      border-radius: 9px;
      box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
      padding: 0 10px;
      font-weight: 400;
      font-family: 'Gotham';
      font-size: 15px;
      line-height: 18px;
      color: #181818;
      letter-spacing: 0.02em;
      border: 0.5px solid grey;
      background-color: #ffff;
    }
  }
}

.phoneInputClass {
  height: 40px !important;
  width: 100% !important;
  border: 0 !important;
  border-radius: 9px !important;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12) !important;
  font-weight: 400 !important;
  font-family: 'Gotham' !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #181818 !important;
  letter-spacing: 0.02em !important;
  border: 0.5px solid grey;
}

.phoneButtonClass {
  border: 0 !important;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12) !important;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
}

.support {
  text-align: center;
  margin-top: 10px;
  width: 127%;
}

.upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload_label {
  p {
    display: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #dedfe0;
    border-style: none;
    border-color: #dedfe0;
    border-radius: 5px;
    margin-top: 15px;
    padding: 11px;
    span {
      margin-left: 5px;
      font-weight: 400;
    }
  }
}
.upload_label:hover {
  cursor: pointer;
  span {
    font-weight: 550;
  }
}
.btnContainer:hover {
  cursor: pointer;
}

.allowed {
  font-size: 12px;
  color: rgb(183, 183, 183);
  text-align: center;
}
iframe {
  border: none;
}
.back {
  margin-bottom: 15px;
  a {
    all: unset;
    color: black;
    margin-left: 8px;
    cursor: pointer;
  }
}

.progressBar {
  height: 13px;
  background-color: #d3d9e9;
  border-radius: 8px;
}
.progress5 {
  height: 100%;
  border-radius: 8px;
  width: 100%;
  background-image: linear-gradient(to right, #000080, #8445d5);
}
.submitButton {
  font-family: Georgia, 'Times New Roman', Times, serif;
  border-radius: 10px;
  box-shadow: none;
  border-width: 2px;
  height: 71px;
  font-weight: 400;
  font-size: 19px;
  line-height: 18px;
  background: #000080;
  border-color: #000080;
  color: white;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  padding: 5px;
  letter-spacing: 3px;
}
.frame2 {
  width: 760px;
  background-color: #f5f7fc;
  display: flex;
  flex-direction: column;
  margin: 20px 50px;
  border-radius: 7px;
  padding: 20px 90px;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    p {
      font-family: Gordita;
      font-size: 19px;
      font-weight: 500;
      margin: 10px;
    }
  }
  .twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 580px;
    height: 190px;
    background-color: white;
    border-radius: 9px;
    padding: 20px;
    .title {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      color: #181818;
      letter-spacing: 2%;
      line-height: 22px;
    }
    .content {
      margin: 0;
      font-family: Gordita;
      font-size: 16px;
      font-weight: 500;
      color: #181818;
      letter-spacing: 2%;
      line-height: 23px;
    }
  }
  .cv {
    .file {
      display: flex;
      flex-direction: row;
      background-color: white;
      border-radius: 9px;
      padding: 20px;
      p {
        margin-left: 15px;
      }
    }
  }
  .relExp {
    .jobs {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 9px;
      padding: 20px;
      .title {
        font-family: Gordita;
        font-size: 16px;
        font-weight: 500;
        color: #181818;
        letter-spacing: 2%;
        line-height: 20px;
      }
    }
  }
  .terms {
    div {
      font-family: Gordita;
      font-size: 14px;
      font-weight: 400;
    }
    .termsLink {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    .bigTitle {
      font-family: Gordita;
      font-weight: 600;
      font-size: 20px;
      margin-top: 15px;
    }
    .title {
      font-weight: 500px;
    }
    .content {
      font-weight: 300px;
    }
  }

  .modalCloseButton {
    float: right;
    cursor: pointer;
  }
}

.auto {
  margin: 15px 2px 5px 2px;
  p {
    margin-bottom: 5px;
  }
}

.logIn {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  font-family: Gotham;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  background-color: #000080;
  color: white;
  margin-top: 20px;
  letter-spacing: 4px;
}

.error {
  color: red;
}

.verificationContainer {
  background-color: #f3f4f6;
  // width: 300px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin: auto;
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  p {
    color: #555;
    font-size: 0.9em;
    margin-bottom: 20px;
    span {
      color: #000080;
    }
  }
  .codeInputs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    input {
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: 1.5em;
      border: 1px solid #ccc;
      border-radius: 5px;
      transition: border-color 0.3s;
      margin: 3px;
    }
    :focus {
      outline: none;
      border-color: #000080;
    }
  }
}

.resend a {
  color: #000080;
  text-decoration: none;
  font-weight: bold;
}

.verifyBtn {
  background-color: #000080;
  color: white;
  border: none;
  padding: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  font-family: Gotham;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  background-color: #000080;
  color: white;
  margin-top: 20px;
  letter-spacing: 4px;
  :hover {
    background-color: #000080;
  }
}
.infoReg {
  line-height: 24px;
}
// .success_window {
//   display: flex;
//   width: 500px;
//   height: 250px;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-around;
//   width: 100%;
//   p {
//     line-height: 26px;
//   }
// }
.logInLink {
  color: #000080;
  text-decoration: underline;
  cursor: pointer;
}
.options {
  display: flex;
  flex-direction: row;
  padding: 15px 0 15px 5px;
  justify-content: space-between;
  .rememberCheckbox {
    width: auto;
    margin-right: 4px;
  }

  .forgot {
    color: #000080;
    border-bottom: 1px solid #000080;
    cursor: pointer;
  }
}
.new_account {
  margin-top: 10px;
  cursor: pointer;
}
.titleName {
  margin-top: 9px;
  margin-bottom: 5px;
  line-height: 26px;
}
.contactUsModal .rc-dialog-body {
  overflow: auto;
  max-height: 80vh;
}
