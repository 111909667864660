.container {
  display: flex;
  align-items: center;
  min-width: 150px;
  margin-right: 1rem;

  .text {
    margin-left: 7px;
    font-family: Gordita;
    font-size: 13px;
    font-weight: 400;
  }
}
