.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(0.35px);
  display: flex;
  justify-content: flex-end;
}

.panel {
  background: #fff;
  width: 55vw;
  height: 100%;
  transition: width 3s;
  transition-timing-function: linear;
  transform: translateX(0);
  overflow-y: auto;
  //   transition: transform 0.9s ease-in-out;
  //   animation-timing-function: ease-in-out;
  //   animation-timing-function: steps(4, end);
  //   animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
  padding: 20px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border: 1px solid lightgray;
}
.panel.closed {
  transform: translateX(100%);
}
.panelTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.closeButton {
  //   position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.linkToProfile {
  color: #000080;
}

.top {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border: 1px solid lightgray;
  border-radius: 10px;

  .profilePicture {
    margin-right: 13px;
    width: 15%;
  }

  .profileDetails {
    font-size: 19px;
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 500;
    flex: 1;

    .name {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      position: relative;
    }

    .location {
      font-family: 'Gordita';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-top: 10px;

      span {
        color: #181818;
      }

      div {
        margin-top: 32px;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 27px;
        color: rgba(24, 24, 24, 0.84);
      }
    }
  }
}
.about {
  margin-top: 15px;
  letter-spacing: 0.02;
  line-height: 24px;
  h3 {
    margin-bottom: 5px;
  }
  p {
    font-weight: 350 !important;
  }
}

.experience {
  h4 {
    margin-bottom: 4px;
  }
}

// added

.applicationDetails {
  background-color: #f5f7fc;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  margin: 20px 0;
  border-radius: 7px;
  padding: 20px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    p {
      font-family: Gordita;
      font-size: 19px;
      font-weight: 500;
      margin: 10px;
    }
  }
  .twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    // width: 580px;
    // height: 190px;
    background-color: white;
    border-radius: 9px;
    padding: 20px;
    .title {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      color: #181818;
      letter-spacing: 2%;
      line-height: 22px;
    }
    .content {
      margin: 0;
      font-family: Gordita;
      font-size: 16px;
      font-weight: 500;
      color: #181818;
      letter-spacing: 2%;
      line-height: 23px;
    }
  }
  .cv {
    .file {
      display: flex;
      flex-direction: row;
      background-color: white;
      border-radius: 9px;
      padding: 20px;
      p {
        margin-left: 15px;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .relExp {
    .jobs {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 9px;
      padding: 20px;
      .title {
        font-family: Gordita;
        font-size: 16px;
        font-weight: 500;
        color: #181818;
        letter-spacing: 2%;
        line-height: 20px;
      }
    }
  }
  .terms {
    div {
      font-family: Gordita;
      font-size: 14px;
      font-weight: 400;
    }
    .termsLink {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    .bigTitle {
      font-family: Gordita;
      font-weight: 600;
      font-size: 20px;
      margin-top: 15px;
    }
    .title {
      font-weight: 500px;
    }
    .content {
      font-weight: 300px;
    }
  }

  .modalCloseButton {
    float: right;
    cursor: pointer;
  }
}
