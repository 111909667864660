.headerContainer {
  background-color: #ecf1ff;
  width: 100%;
  text-align: center;
  padding: 40px 80px;
  font-family: 'Gotham';
  display: flex;
  justify-content: space-between;
  display: flex;
  background-image: url('../../assets/bg-profile.png');
  background-size: 100%;

  .main {
    display: flex;

    .jobCardIcon {
      width: 60px;
      height: 60px;
      border-radius: 50vh;
      margin-right: 15px;
    }

    .jobCardTextContent {
      flex: 1;
      font-family: 'Gordita';
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      display: flex;

      .jobCardTextTitle {
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        align-items: center;

        .title {
          font-family: 'Gordita';
          font-size: 18px;
          color: #181818;
          font-style: normal;
          font-weight: 500;
          margin-right: 17px;
        }
      }

      .jobCardTextCompanyName {
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        height: 24px;
      }

      .jobLocationPay {
        display: flex;
        align-items: center;
        color: #181818;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 350;
        font-size: 13px;
        height: 20px;
      }
    }
  }
  .right {
    .application {
      font-family: 'Gordita';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      height: 30px;
      .red {
        color: red;
      }
    }
    .action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      .backgroundBookmarkBlue {
        border-radius: 50vh;
        background: #000080;
        display: flex;
        width: 8vh;
        height: 8vh;
        align-items: center;
        justify-content: center;
      }
      .backgroundBookmarkGrey {
        border-radius: 50vh;
        background-color: #d3e2fe;
        display: flex;
        width: 8vh;
        height: 8vh;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
