.manageJobs {
  // Styles for the main manage jobs container

  .header {
    h1 {
      font-size: 26px;
      font-weight: bolder;
      color: #181818;
      line-height: 31px;
      font-family: Didact Gothic;
    }
    p {
      font-size: 15px;
      color: #565758;
    }
  }
  .background {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: white;
    padding: 15px 20px;
    border-radius: 9px;
  }
  h2 {
    color: #1c1f37;
  }

  .topData {
    justify-content: space-between;
    display: flex;
  }

  .statsCard {
    display: flex;
    align-items: center;
    // justify-content: center;
    .rocketBack {
      width: 64px;
      height: 64px;
      background-color: #e1eefb;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .statJobs {
      display: flex;
      justify-content: center;
      margin-left: 15px;
      flex-direction: column;
      .totalJobs {
        color: #565758;
        margin-top: 30px;
      }
      .totalJobsCount {
        font-size: 30px;
      }
    }
  }

  .jobsTable {
    width: 100%;
    border-collapse: collapse;

    th {
      background-color: #f3f3f3;
      color: #333;
      font-weight: bold;
      padding: 12px 15px;
      text-align: left;
      height: 60px;
    }
    tr {
      border-radius: 4px;
    }

    tr:nth-child(odd) {
      background-color: white; // White for odd rows
    }

    tr:nth-child(even) {
      background-color: #f0f6f8; // Light blue for even rows
    }

    td {
      padding: 12px 15px;
      border-bottom: 1px solid #eee;

      &:last-child {
        display: flex;
        justify-content: center;
        gap: 10px; // Adjust the gap as needed
      }
    }

    .actionButton {
      border: none;
      cursor: pointer;
      padding: 5px;
      font-size: 1rem;
      width: 34px;
      height: 34px;
      border-radius: 5px;

      &:hover {
        background-color: #e8e8e8; // Or any other hover effect you'd like
        box-shadow: 3px 1px 1px #b3b9be;
      }
    }

    .viewDetailsButton {
      background-color: rgba(0, 0, 128, 0.1); // lightblue
    }

    .editButton {
      background-color: rgba(243, 139, 8, 0.2); // light orange
    }

    .trashButton {
      background-color: rgba(203, 31, 39, 0.13); // light red
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
  }
}
