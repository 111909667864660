.container {
  position: relative;
}
.input {
  height: 50px;
  background-color: white;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
  border-radius: 9px;
  display: flex;
  align-items: center;
  padding: 16px 22px 15px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #181818;
  border: 0.5px solid grey;
  .icon {
    margin-top: 15px;
    margin-left: 8px;
  }

  .text {
    flex: 1;
    margin-left: 11px;
    font-family: 'Gotham';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #181818;
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
  }
}

.list {
  font-family: Gotham;
  font-weight: 400;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #f3f3f3;
  border-top: none;
  box-shadow: 0px 6px 20px rgba(64, 79, 104, 0.11);
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-radius: 10px;
  max-height: 400px;
  overflow: scroll;
  position: absolute;
  z-index: 5;
  width: 100%;

  li {
    padding: 10px;
    cursor: pointer;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.07);

    &:hover {
      background-color: #f1f1f1;
    }
  }
}

.gray {
  background-color: #f0f5f7;
}

.errorInput {
  border: 1px solid red;
}
.errorMessage {
  color: red;
}
