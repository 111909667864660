.filters {
  display: flex;
  background: #f5f7fc;
  border-radius: 9px;
  padding: 11px 19px;
  flex-wrap: wrap;

  .selectedFilters {
    display: flex;
    padding: 10px 14px;
    align-items: center;
    background: #dde7ff;
    border-radius: 9px;
    margin-right: 1vh;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #181818;
    margin-top: 2px;

    p {
      flex: 1;
      margin: 0 10px 0 0;
    }

    img {
      cursor: pointer;
    }
  }
  .clearEmpty {
    flex: 1;
  }
}
