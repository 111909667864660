.title {
  font-size: 26px;
  font-weight: bolder;
  color: #181818;
  line-height: 26px;
  font-family: Didact Gothic;
  padding-top: 0px;
}

.main {
  background-color: white;
  padding: 30px;
  border-radius: 9px;
  width: 100%;
  // margin-left: 150px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  input {
    background-color: #f0f5f7;
    height: 50px;
    width: 100%;
    border: 0;
    border-radius: 9px;
    box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
    padding: 0 10px;
    font-weight: 400;
    font-family: 'Gotham';
    font-size: 15px;
    line-height: 18px;
    color: #181818;
    letter-spacing: 0.02em;
    border: 0.5px solid grey;
    margin-top: 5px;
  }
  .message {
    display: flex;
    flex-direction: column;
    textarea {
      background-color: #f0f5f7;
      min-height: 230px;
      width: 100%;
      border: 0;
      border-radius: 9px;
      box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
      padding: 10px;
      font-weight: 400;
      font-family: 'Gotham';
      font-size: 15px;
      line-height: 18px;
      color: #181818;
      letter-spacing: 0.02em;
      border: 0.5px solid grey;
      margin-top: 5px;
      outline: none;
      :focus {
        outline: none !important;
      }
    }
  }
  .btnSave {
    display: flex;
    justify-content: end;
  }

  .error {
    color: red;
    font-weight: 400;
    font-size: 16px;
    margin-top: 4px;
  }
}

.success_window {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  line-height: 24px;

  .success_message {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  button {
    margin-top: 20px;
  }
}
