.btn {
    font-family: Georgia, 'Times New Roman', Times, serif;
    border-radius: 10px;
    box-shadow: none;
    border-width: 2px;
    cursor: pointer;
}
.btn:hover{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
.btn:active{
    opacity: 0.8;
}

.small {
    font-size: 15px;
    width: 132px;
    height: 44px;
    font-weight: 400;
}

.medium {
    width: 200px;
    height: 56px;
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.large {
    width: 13vw;
    height: 71px;
    font-weight: 400;
    font-size: 19px;
    line-height: 18px;
}

.basic {
    background: #ECF1FF;
    color: #000080;
    border-color: #ECF1FF;
}

.secondary {
    background-color: #F38B08;
    border-color: #F38B08;
    color: white;
    font-weight: 500;
}

.primary {
    background: #000080;
    border-color: #000080;
    color: white;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
}

.link {
    color: #F74646;
    border: none;
    font-weight: 400;
    font-size: 15px;
    font-family: 'Gordita';
    margin-top: 2px;
    padding: 5px;
    cursor: pointer;
}