.badge {
    border-radius: 30px;
    padding: 7px 19px;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    }

    &.blue{
        background-color: #E1EAFF;
        color: #1548BF;

    }
    &.yellow{
        background: #FFE1BC;
        color: #CC7202;
    }
}