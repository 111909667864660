.header {
  display: grid;
  grid-template-columns: 1fr 5fr 1.2fr;
}
// p {
//   font-family: 'Gordita';
//   line-height: 21px;
// }
.main2 {
  display: flex;
  p {
    font-family: 'Gotham';
    font-weight: 500;
  }
}
.child1 {
  background: white;
  border: 1px solid #dcdcdc;
  padding: 24px;
  display: flex;
  flex-direction: row;
  width: 232px;

  img {
    margin: 0px 15px 0 0;
  }
}
.child2 {
  background: white;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  align-items: center;

  img {
    position: absolute;
    top: 29px;
    left: 260px;
  }
  input {
    color: #898989;
    padding: 8px;
    padding-left: 27px;
    background-color: #f0f5f7;
    margin: 16px 25px;
    height: 40px;
    width: 380px;
    border-radius: 6px;
    border-style: none;
  }
}
.child3 {
  background: white;
  border-bottom: 1px solid #dcdcdc;
  padding: 11px 40px 0 0;
  .navBarRight {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .account {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #ecf1ff;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    height: 56px;
    width: 91px;
  }
}
.child4 {
  padding: 15px 25px 15px 15px;
  // padding-left: 15px;
  // padding-right: 25px;
  background: white;
  border-right: 1px solid #dcdcdc;
  font-family: Gordita;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  width: 232px;
  p {
    margin-left: 8px;
    font-family: 'Gordita';
  }
  .it1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 15px;
      height: 17px;
    }
  }
  .item {
    border: 1px solid transparent;

    padding-left: 8px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin-top: 15px;
      color: black;
    }
  }
  .selected {
    padding-left: 8px;
    text-decoration: none;
    border: 1px solid #ecf1ff;
    border-radius: 10px;
    background-color: #ecf1ff;
    display: flex;
    flex-direction: row;
    color: #000080;
    align-items: center;
    p {
      margin-top: 15px;
    }
  }
  .it4 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .it5 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .it6 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .it7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    .redCircle {
      border: 1px solid #cb1f27;
      border-radius: 50%;
      background-color: #cb1f27;
      padding: 0 1px;
      margin-left: auto;
      p {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        color: white;
        margin: 0;
        justify-content: center;
      }
    }
  }
  .it8 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .it9 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .it10 {
    margin-bottom: auto;
    color: #898989;
    align-items: end;
    font-weight: 400;
    display: flex;
    flex: 1;
    margin-bottom: 50px;
    p {
      font-family: Gordita;
      font-weight: 400;
      font-size: 15px;
    }
    span {
      color: #000080;
      text-decoration: underline;
    }
  }
}
.child5 {
  background: #f0f5fb;
  padding: 50px 5rem;
  flex: 1;
  width: 100%;
  height: calc(100vh - 78px);
  overflow-y: scroll;
}
