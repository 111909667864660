.jobCardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #E4E4E4;
    border-radius: 6px;
    padding: 10px 20px 10px 30px;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.1);
    height: 125px;
    margin-top:18px;
    &:hover{
        border: 1px solid #b4c6f0;
        box-shadow: 0px 0px 22px #b4c6f0;
    }
    cursor: pointer;
}

.jobCardIcon {
    width: 60px;
    height: 60px;
    border-radius: 50vh;
    margin-right: 15px;
}

.jobCardTextContent {
    flex: 1;
    font-family: 'Gordita';
    display:flex;
    flex-direction: column;
    justify-content: space-between;

    .jobCardTextTitle {
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        align-items: center;

        .title{
            font-family: 'Gordita';
            font-size: 18px;
            color: #181818;
            font-style: normal;
            font-weight: 500;
            margin-right: 17px;
        }

        .backgroundBookmarkBlue {
            border-radius: 50vh;
            background: #000080;
            display: flex;
            width: 8vh;
            height: 8vh;
            align-items: center;
            justify-content: center;
        }
        .backgroundBookmarkGrey {
            border-radius: 50vh;
            background: #ECECEC;
            display: flex;
            width: 8vh;
            height: 8vh;
            align-items: center;
            justify-content: center;
        }
    }

    .jobCardTextCompanyName{
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        height: 24px;
    }


    .jobLocationPay {
        display: flex;
        align-items: center;
        color: #181818;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 350;
        font-size: 13px;
        height: 20px;
    }

}

.empty {
    flex: 1;
}
