.navBarOption {
  text-align: center;
  text-decoration: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #181818;
  font-family: 'Didact Gothic';
  font-style: normal;
  font-weight: 600;
}
.navBarOptionActive {
  text-align: center;
  text-decoration: none;
  color: #181818;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid #f38b08;
  font-family: 'Didact Gothic';
  font-style: normal;
  font-weight: 600;
}
