.orange {
  color: orange;
}
.blue {
  color: navy;
}
.logo {
  font-size: 24px;
  height: 24px;
  cursor: pointer;
  font-family: 'Didact Gothic';
  font-style: normal;
  font-weight: 600;


  h2 {
    margin: 0;
  }
}
