.container {
  padding: 45px 40px;
  background-color: #f5f7fc;
  margin: 60px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 9px;
  .title {
    font-size: 26px;
    font-weight: bolder;
    color: #181818;
    line-height: 31px;
    font-family: Didact Gothic;
  }
  .explain {
    font-size: 15px;
    color: #565758;
    font-family: 'Gordita';
    font-weight: 500;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
      // Style for the stats (Saved Jobs, Applied, Interviews)
      background: #f2f2f2; // Example background color
      padding: 5px 10px;
      border-radius: 10px;
      // Add font styles as required
    }
  }

  .jobList {
    // Style for the job list container
  }

  .jobCard {
    display: flex;
    align-items: center;
    background: #fff; // Example background color
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // Example shadow

    cursor: pointer;
    &:hover {
      border: 1px solid #b4c6f0;
      box-shadow: 0px 0px 22px #b4c6f0;
    }

    .logo {
      width: 50px; // Adjust as needed
      height: 50px;
      margin-right: 10px;
      img {
        max-width: 100%;
        border-radius: 50%;
      }
    }

    .details {
      flex-grow: 1;

      h2 {
        margin: 0;
      }
    }

    .actions {
      // Style for the action buttons
      display: flex;
      justify-content: center;
      gap: 10px;
      // button {
      //   background: #007bff; // Example button color
      //   color: white;
      //   border: none;
      //   padding: 10px 20px;
      //   border-radius: 20px;
      //   cursor: pointer;
      //   margin-left: 10px;

      //   &:hover {
      //     background: darken(#007bff, 10%);
      //   }
      // }
    }
  }

  .loadMore {
    width: 100%;
    padding: 10px 20px;
    background: #eee; // Example button color
    color: #333;
    border: none;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      background: darken(#eee, 10%);
    }
  }
}

.top {
  display: flex;
  justify-content: space-between;
  padding-top: 90px;
  padding-left: 100px;
  padding-right: 100px;

  // padding: 123px 150px 0 123px;
  display: flex;
  flex: 1;
  align-items: center;
  background-image: url('../../assets/bg-profile.png');
  background-size: 100%;

  .profilePicture {
    margin-right: 13px;
  }

  .profileDetails {
    font-size: 19px;
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 500;
    flex: 1;
    display: flex;
    justify-content: flex-end; /* Align items to the right */
    align-items: center;
    justify-content: space-between; /* Align items to the right and keep space between them */

    .name {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      position: relative;
      margin-right: 10px;
    }

    .location {
      font-family: 'Gordita';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-right: auto;

      span {
        color: #181818;
      }

      div {
        margin-top: 32px;
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 27px;
        color: rgba(24, 24, 24, 0.84);
      }
    }
  }
}
.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
}
.filter {
  display: flex;
}
.tab {
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid transparent;
  background-color: #cdc9c9; /* Light grey background for inactive tabs */
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s, box-shadow 0.5s, ease-in-out;
  color: white;
  margin: 5px;
  &:hover {
    opacity: 2;
  }
  &.active {
    color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
    transition: background-color 0.3s ease-in-out;
    border-bottom: 1px solid transparent;
    background-color: #000080;
  }
}
.locationPay {
  display: flex;
  color: #696969;
  font-size: 15px;
  align-items: center;
  p {
    display: flex;
  }
  .locationPayLine {
    margin-left: 15px;
  }
  span {
    margin-left: 7px;
  }
}

.actionButton {
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1rem;
  width: 34px;
  height: 34px;

  border-radius: 5px;

  &:hover {
    background-color: #e8e8e8;
    box-shadow: 3px 1px 1px #b3b9be;
  }
}
.viewDetailsButton {
  background-color: rgba(0, 0, 128, 0.1); // lightblue
}
.trashButton {
  background-color: rgba(203, 31, 39, 0.13); // light red
  border: 0;
}
.loadMoreContainer {
  background-color: #f5f7fc;
  text-align: right;
  padding: 0px 20px 20px;
  border-radius: 0 0 10px 10px;
}
