.react-date-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
  line-height: 18px;
  height: 50px;
  border: 0.5px solid grey;
  border-radius: 9px;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
  width: 100%;
  line-height: 18px;
  height: 50px;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  border: 0;
  border-radius: 9px;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
  padding: 0 10px;
  font-weight: 400;
  font-family: 'Gotham';
  font-size: 15px;
  line-height: 18px;
  color: #181818;
  letter-spacing: 0.02em;
  background: #f0f5f7;
  width: 100%;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 100%;
  max-width: 100%;
  z-index: 3;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
  background: #f0f5f7;
  border-radius: 8px;
}
.react-date-picker__calendar--open {
  top: 100% !important;
  bottom: unset !important;
}
