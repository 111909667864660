.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 59px;
  margin-top: 20px;
}

.slider {
  position: relative;
  width: 100%;
}

.sliderTrack,
.sliderRange,
.sliderLeftValue,
.sliderRightValue {
  position: absolute;
}

.sliderTrack,
.sliderRange {
  border-radius: 3px;
  height: 2px;
}

.sliderTrack {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.sliderRange {
  background-color: #000080;
  z-index: 2;
}

.sliderLeftValue,
.sliderRightValue {
  color: #181818;
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
}

.sliderLeftValue {
  left: 6px;
}

.sliderRightValue {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 23%;
}

.thumbZindex3 {
  z-index: 3;
}

.thumbZindex4 {
  z-index: 4;
}

.thumbZindex5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 5px solid #000080;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 3px solid #000080;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
}
