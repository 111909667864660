.navBarContainer {
  display: flex;
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #181818;
  min-height: 66px;
}
.navBarLeft {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.space {
  flex-grow: 8;
}
.navBarRight {
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
