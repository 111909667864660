.headerContainer {
    background-color: #ECF1FF;
    text-align: center;
    padding: 7vh 10vw;
    font-family: 'Gotham';

    h1{
        font-size:5vh;
    }
    h2{
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 14px;
        color: rgba(24, 24, 24, 0.84);
    }
}
.headerSearchContainer{
    display:flex;
    align-items: center;
}