.page {
  display: flex;
  flex-direction: column;

  .top {
    padding: 123px 150px 0 123px;
    display: flex;
    flex: 1;
    align-items: center;
    background-image: url('../../assets/bg-profile.png');
    background-size: 100%;
    gap: 18px;

    .profilePicture {
      margin-right: 13px;
      border-radius: 50%;
    }

    .profileDetails {
      font-size: 19px;
      font-family: 'Gordita';
      font-style: normal;
      font-weight: 500;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: stretch;
      padding: 16px 0px;

      .name {
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        position: relative;
        margin-bottom: 10px;
      }

      .location {
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;

        .address {
          display: flex;
          gap: 8px;
          align-items: center;
        }

        span {
          color: #181818;
        }

        .user_title {
          font-family: 'Gordita';
          font-style: normal;
          font-weight: 500;
          font-size: 19px;
          line-height: 27px;
          color: rgba(24, 24, 24, 0.84);
        }
      }
    }
  }

  .main {
    margin: 40px 150px 200px 130px;
    display: flex;

    .mainCenter {
      width: 70%;

      .about {
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding: 30px;
        position: relative;
        height: 200px;

        .roundBlueBackground {
          right: 10px;
          top: 10px;
          display: inline-flex;
          width: 45px;
          height: 45px;
          justify-content: center;
          align-items: center;
          position: absolute;
          border: 1px solid #cfdeff;
          border-radius: 50%;
          background: #cfdeff;
          padding: 3px 9px;
          margin-left: 10px;
        }

        .title {
          font-family: 'Gordita';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 135%;
          letter-spacing: 0.02em;
        }

        .content {
          font-family: 'Gordita';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #181818;
        }
      }
    }

    .mainRight {
      width: 86%;

      .preferences {
        background-color: #f5f7fc;
        padding: 30px;
        position: relative;

        .pencil {
          cursor: pointer;
          position: absolute;
          right: 15px;
          justify-content: center;
          align-items: center;
          right: 12px;
          top: 12px;
          z-index: 0;
          margin-left: 10px;
        }

        .paragraphTitle {
          font-family: 'Gordita';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 135%;
          letter-spacing: 0.02em;
          color: #181818;
        }

        .component {
          .title {
            font-family: 'Gordita';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 135%;
            letter-spacing: 0.02em;
            color: #181818;
            margin: 15px;
          }

          .badges {
            display: flex;
            flex-wrap: wrap;
          }
        }
      }
    }

    .skills {
      background-color: #f5f7fc;
      padding: 30px;
      margin-top: 20px;
      width: 86%;
      position: relative;

      .pencil {
        position: absolute;
        right: 15px;
        cursor: pointer;
      }

      .paragraphTitle {
        font-family: 'Gordita';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 135%;
        letter-spacing: 0.02em;
        color: #181818;
        margin: 15px;
      }
    }
  }
}

.badges {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  li {
    list-style: none;
    font-size: 15px;
    background-color: #dde7ff;
    letter-spacing: 0.02em;
    border-radius: 7px;
    padding: 10px 13px;
    margin: 2px;
  }
}

.marginRight {
  margin-right: 20px;
}

.certificates {
  background-color: #f5f7fc;
  position: relative;
  h2 {
    color: #181818;
    font-family: Gordita;
    font-weight: 500;
    font-size: 18px;
    line-height: 24.3px;
    padding: 23px 0 22px 37px;
    margin: 0;
  }
  .roundBlueBackground {
    z-index: 1;
    display: inline-flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 1px solid #cfdeff;
    border-radius: 50%;
    background: #cfdeff;
    padding: 3px 9px;
    margin-left: 10px;
    right: 12px;
    top: 12px;
    img {
      z-index: 1;
      position: absolute;
      color: blue;
      right: 0;
      left: 12px;
      top: 10px;
    }
  }
}

// .roundBlueBackground {
//   display: inline-flex;
//   width: 45px;
//   height: 45px;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   top: -15px;
//   border: 1px solid #cfdeff;
//   border-radius: 50%;
//   background: #cfdeff;
//   padding: 3px 9px;
//   margin-left: 10px;
// }

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.contactDetails {
  p {
    margin: 20px 0px;
    .span {
      color: #898a8c;
    }
  }

  .input {
    height: 40px;
    width: 100%;
    border: 0;
    border-radius: 9px;
    box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
    padding: 0 10px;
    font-weight: 400;
    font-family: 'Gotham';
    font-size: 15px;
    line-height: 18px;
    color: #181818;
    letter-spacing: 0.02em;
  }
  &:first-child {
    margin-right: 10px;
  }
}
// .contactDetails1 {
//   input {
//     margin-left: 10px;
//   }
// }

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.roundBlueBackground {
  cursor: pointer;
  display: inline-flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  border: 1px solid #cfdeff;
  border-radius: 50%;
  background: #cfdeff;
  padding: 3px 9px;
  margin-left: 10px;
}

.dialogAboutModal {
  min-height: 30%;
}

.textarea {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
  resize: none;
  overflow-y: auto;
  min-height: 200px;
}

.textarea:focus {
  outline: none;
}

.size100 {
  width: 100px !important;
  height: 100px !important;
}

.croppedLogoBlock {
  display: flex;
  flex-direction: column;
  // img {
  //   width: 150px;
  //   margin-bottom: 10px;
  //   border-radius: 50%;
  // }
}
.buttonLogo {
  border: 0 !important;
  border-radius: 9px !important;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12) !important;
  font-weight: 400 !important;
  font-family: 'Gotham' !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: white !important;
  letter-spacing: 0.02em !important;
  background-color: #109fd7 !important;
  // background-color: #0711ce;
  cursor: pointer;
  margin-top: 20px;
  padding: 10px;
}
