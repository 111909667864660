.radioButtonGroup {
    display: flex;
    flex-direction: column;
}

.radioButtonContainer {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 16px;

    input[type="radio"] {
        display: none;
    }

    label {
        cursor: pointer;
        position: relative;
        padding-left: 25px;
    }

    span {
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: 0px 0px 0px #000000;
        border: 5px solid #ababab;
        height: 18px;
        width: 18px;
        border-radius: 9px;
        background: #ffffff;
        cursor: pointer;
    }

}


span.selected {
    box-shadow: 0px 0px 0px #000000;
    border: 5px solid #000080;
}

.unselected:hover {
    span {
        border-color: #000000;
    }
}

.radioButtonContainer input[type="radio"]:checked+label span {
    border-color: #000080;
}