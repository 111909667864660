.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;

  h1 {
    font-size: 2rem;
    color: #333;
  }

  p {
    font-size: 1.5rem;
    color: #666;
  }
}
