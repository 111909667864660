.success_window {
  display: flex;
  width: 500px;
  height: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  p {
    line-height: 26px;
  }
}
