.section {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 16px 16px 16px 30px;
  margin: 25px 0;
  font-family: Gordita;
  position: relative;
}
.sectionTitle {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: 0.02em;
  margin: 30px 0;
}

.sectionContainer {
  position: relative; /* Added to create the positioning context */
}

.sectionItem {
  display: flex;
  padding-bottom: 31px;
  position: relative;
}

.logo {
  width: 50px;
  height: 50px;
  // border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  background: white;
  z-index: 2;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  flex: 1;
}

.info {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
}

.infoTitle {
  font-weight: bold;
  margin-right: 8px;

  font-size: 16px;
  line-height: 24px;
}

.date {
  color: #626262;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.company {
  font-weight: 400;
  margin-bottom: 4px;
  color: #1967d2;
  font-size: 14px;
  line-height: 21px;
}

.text {
  color: #181818;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.connector {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  margin-left: 25px; /* Adjust the value based on the logo size and alignment */

  border-left: 2px dashed #6a8fed;
  // background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  // background-position: right;
  // background-size: 1px 3px;
  // background-repeat: repeat-y;
}

.sectionItem:last-child .connector {
  display: none; /* Hide the connector on the last section item */
}

.roundBlueBackground {
  cursor: pointer;
  right: 10px;
  top: 10px;
  display: inline-flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 1px solid #cfdeff;
  border-radius: 50%;
  background: #cfdeff;
  padding: 3px 9px;
  margin-left: 10px;
}
