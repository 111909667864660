.pages {
    display: flex;
    flex-direction: row;
    margin-top: 70px;
}

.page {
    border: 1px solid #181818;
    border-radius: 50%;
    width: 49px;
    height: 48px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    margin: 5px;

    &.selected {
        border: 1px solid #185BC3;
        color: #FFFFFF;
        background-color: #000080;
    }
}

.dots {
    width: 49px;
    height: 48px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.arrowRight {
    border: 1px solid #181818;
    border-radius: 50%;
    width: 49px;
    height: 48px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    margin: 5px;
    background-color: white;

    &:disabled {
        border: none;
    }
}