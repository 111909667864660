.main {
  background-color: #f6f7fc;
  height: 90%;
  .left {
    margin: 30px 10px 50px 30px;
    .search {
      background-color: white;
      width: 420px;
      height: 70px;
      border-radius: 6px;
      border: 1px solid #e4e4e4;
      display: flex;
      color: #707c97;
      font-size: 16px;
      font-family: 'Gordita';
      .search_left {
        width: 60%;
        border-right: 2px solid #f5f4fb;
        padding: 20px 5px 10px 30px;
        b {
          display: inline;
          margin-left: 7px;
          font-weight: 410;
        }
      }
      .search_filter {
        width: 40%;
        display: flex;
        align-items: center;
        padding: 5px 5px 10px 30px;
        p {
          margin-right: 7px;
          padding-top: 5px;
        }
        img {
          margin-top: 10px;
        }
      }
    }
    .messageCardsCont {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      .messageCard1 {
        border: 1px solid #e4e4e4;
        padding: 15px 15px 11px 15px;
        background-color: #ffffff;
        border-radius: 6px;
        top: 165px;
        width: 420px;
        .top {
          .left {
            margin: 30px 10px 10px 30px;
            display: flex;
            flex-direction: row;
            font-family: Gordita;
            .logoNotif {
              .greenCircle {
                position: absolute;
                border: 1px solid white;
                border-radius: 50%;
                padding: 5px;
                background-color: #00c305;
                margin-left: 3px;
              }
              .logo {
                border: 1.5px solid #ffffff;
                width: 54px;
                height: 54px;
                border-radius: 27px;
              }
            }

            .description {
              margin-left: 7px;
              display: flex;
              flex-direction: column;
              .name {
                font-size: 18px;
                color: #0d1c2e;
                font-weight: 700;
              }
              .status {
                font-size: 13px;
                color: #000080;
                margin-top: 9px;
              }
            }
            .timeAgo {
              color: #707c97;
              margin-left: auto;
              font-size: 13px;
              font-weight: 400;
            }
          }
        }
        .bottom {
          display: flex;
          .content {
            padding-left: 28px;
            color: #181818;
            font-family: Gordita;
            font-size: 16px;
            line-height: 26px;
          }
          .newMessages {
            font-family: Gordita;
            font-size: 10px;
            border: 1px solid white;
            padding: 3px;
            background-color: #f38b08;
            color: white;
            border-radius: 50%;
            font-size: 13px;
            height: 18px;
            width: 18px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
