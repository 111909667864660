.dialogExperiencesModal {
  width: 50%;
}
.shortWidth {
  width: 40%;
}

th {
  background-color: #f3f3f3;
  color: #333;
  font-weight: bold;
  padding: 12px 15px;
  text-align: left;
  height: 60px;
}
tr {
  border-radius: 4px;
}

tr:nth-child(odd) {
  background-color: white; // White for odd rows
}

tr:nth-child(even) {
  background-color: #f0f6f8; // Light blue for even rows
}

td {
  padding: 12px 15px;
  border-bottom: 1px solid #eee;

  &:last-child {
    display: flex;
    justify-content: center;
    gap: 10px; // Adjust the gap as needed
  }
}

.actionButton {
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1rem;
  width: 34px;
  height: 34px;
  border-radius: 5px;

  &:hover {
    background-color: #e8e8e8; // Or any other hover effect you'd like
    box-shadow: 3px 1px 1px #b3b9be;
  }
}

.editButton {
  background-color: rgba(243, 139, 8, 0.2); // light orange
}

.trashButton {
  background-color: rgba(203, 31, 39, 0.13); // light red
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.formLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.formLayout div:not(:first-child) {
  margin-top: 10px;
}

.red {
  color: red;
}
