* {
  /* don't use font to not break WYSIWYG */
  /* font-family: 'Didact Gothic';
  font-style: normal;
  font-weight: 600; */
  box-sizing: border-box;
}
html,
body,
#root,
.app {
  height: 100%;
}
body {
  margin: 0px;
}
.app {
  display: flex;
  flex-direction: column;
}

input {
  font-family: 'Gotham';
  font-weight: 300;
}
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
