.checkboxGroup {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #181818;
  label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    display: inline-block;
    height: 18px;
    width: 18px;
    border: 1px solid #ababab;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 8px;
    position: relative;
  }

  input[type='checkbox']:checked + .checkmark {
    background-color: #000080;
  }

  input[type='checkbox']:unchecked + .checkmark {
    background-color: gray;
  }

  input[type='checkbox']:checked + .checkmark:after {
    display: block;
  }

  .checkmark::after {
    content: '';
    position: absolute;
    display: none;
  }

  .checkbox:checked + .checkmark::after {
    display: block;
  }

  .checkmark::after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
