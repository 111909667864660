.title {
  font-size: 26px;
  font-weight: bolder;
  color: #181818;
  line-height: 31px;
  font-family: Didact Gothic;
}
.intro {
  font-family: Gordita;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
}
.prompt {
  font-family: Gordita;
  font-size: 15px;
  color: #565758;
  font-weight: 400;
  line-height: 21px;
}
.form {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  color: #565758;
  font-size: 15px;
  padding-bottom: 150px;
  label {
    font-family: Gordita;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding: 25px 0 10px 0;
    display: block;
  }
}
.contactDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
}
.range {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}
textarea {
  background-color: #f0f5f7;
  border-radius: 6px;
  border: none;
  padding: 15px;
}
.certificates {
  display: flex;
  > div {
    flex: 1;
  }
  button {
    top: 2px;
    right: 8px;
    background-color: black;
    color: #f0f5f7;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 16px;
  }
}

.saveBtn {
  margin-top: 50px;
  float: right;
}

// Styles for WYSIWYG
.toolbarClassName {
  background-color: #f0f5f7 !important;
  div {
    background-color: #f0f5f7;
    color: #333333;
  }
}

.wrapperClassName {
  background-color: #f0f5f7;
  border: 0.1px solid grey;
  border-radius: 9px;
}

.wrapperClassNameError {
  background-color: #f0f5f7;
  border: 1px solid red;
}

.errorBorder {
  border: 1px solid red;
}

.editorClassName {
  background-color: #f0f5f7;
  padding: 25px;
  * {
    font-family: unset;
    font-style: unset;
    font-weight: unset;
  }
}
// End for Styles for WYSIWYG

.selectedCertificates {
  display: flex;
  border-radius: 9px;
  padding: 11px 0px;
  flex-wrap: wrap;

  .certificate {
    display: flex;
    padding: 10px 14px;
    margin: 5px 0;
    align-items: center;
    background: #dde7ff;
    border-radius: 9px;
    margin-right: 1vh;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #181818;
    margin-top: 2px;

    p {
      flex: 1;
      margin: 0 10px 0 0;
    }
    img {
      cursor: pointer;
    }
  }
  .clearEmpty {
    flex: 1;
  }
}
.errorMessage {
  margin-top: 4px;
  color: red;
}
.success_window {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  line-height: 24px;

  .success_message {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  button {
    margin-top: 20px;
  }
}
.jobTitleAutoComplete {
  margin-right: 0;
  padding-left: 0;
  height: 50px;
  width: 100%;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
  // background-color: #f0f5f7;
  div {
    width: 100%;
    input {
      flex-grow: 1;
      width: auto !important; // to match other elements
      background-color: transparent; // to match other elements
      font-weight: 400 !important; // to match other elements
    }
  }
}
