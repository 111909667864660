* {
  font-family: Gotham;
}

.options {
  display: flex;
  flex-direction: row;
  padding: 15px 0 15px 5px;
  justify-content: space-between;
  align-items: center;
  .forgot {
    color: #000080;
    text-decoration: underline;
    cursor: pointer;
  }
  .remember {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    label {
      color: #393939;
      font-weight: 400;
      margin-left: 7px;
    }
  }
}
.logIn {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  font-family: Gotham;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  background-color: #000080;
  color: white;
  margin-top: 20px;
}
.text {
  color: #6b6b6b;
}
.new_account {
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0;
  .sign_up {
    cursor: pointer;
    color: #000080;
    border-bottom: 1px solid #000080;
    margin-left: 7px;
    font-weight: 450;
  }
}
.or {
  color: #878686;
  font-weight: 300;
  margin-top: 20px;
  font-size: 14px;
}
.other {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .button {
    border: 1px solid lightgray;
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 27px;
      height: 27px;
    }
    span {
      margin-left: 10px;
    }
  }
  .button1 {
    color: #000080;
    background-color: #dde7ff;
    border-radius: 8px;
    padding: 20px 70px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    span {
      margin-left: 10px;
    }
  }
  .button2 {
    color: white;
    background-color: #000080;
    border-radius: 8px;
    padding: 20px 70px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    span {
      margin-left: 10px;
    }
  }
}
.error {
  color: red;
}

.success_window {
  display: flex;
  width: 500px;
  height: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  line-height: 30px;
}

.emailContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.title {
  line-height: 27px;
  margin-top: 9px;
  margin-bottom: 5px;
  span {
    color: #878686;
  }
}
