.account {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #ecf1ff;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  height: 56px;
  width: 91px;
}

.menu_container {
  position: relative;
  display: inline-block;
}

.arrow-down {
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 56px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 8px 0;
  margin: 0;
  z-index: 1;
  min-width: 275px;
  border-radius: 12px;
}
.profile {
  display: flex;
  align-items: center;
  // padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid lightgrey;
  img {
    width: 120px;
  }
}
// .picture {
//   margin-left: 15px;
//   margin-right: 15px;
//   width: 20%;
//   height: 20%;
// }
.position {
  font-weight: 250;
  .li {
    color: #000080;
  }
}
.menu li {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.menu li:hover {
  background-color: #f5f5f5;
}
.parent_items {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-between;
  .it1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: 0;
      margin-left: 15px;
    }
  }

  .it1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
  }
}
.selected {
  text-decoration: none;
  border: 1px solid #ecf1ff;
  border-radius: 10px;
  background-color: #ecf1ff;
  display: flex;
  flex-direction: row;
  color: #000080;
  align-items: center;
  p {
    margin-top: 15px;
  }
}
.item {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin-top: 15px;
    color: black;
  }
}
.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-weight: 600;
    font-size: larger;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  a {
    li {
      font-weight: 500;
    }
  }
}
.picture {
  display: flex;
  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding: 5px;
  }
}
