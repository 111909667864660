.container {
  padding: 20px 20px 5px 20px;
  background: #fff;
  border-radius: 10px 10px 0px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f5f7;
    padding: 15px;
    margin-bottom: 25px;
    h2 {
      color: #333;
      font-size: 24px;
      font-weight: 600;
      // margin-bottom: 15px;
    }
    .stats {
      color: #666;
      margin-bottom: 15px;
    }
  }
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.tab {
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid transparent;
  background-color: #cdc9c9; /* Light grey background for inactive tabs */
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s, box-shadow 0.5s, ease-in-out;
  color: white;
  &:hover {
    opacity: 2;
  }
  &.active {
    color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
    transition: background-color 0.3s ease-in-out;
    border-bottom: 1px solid transparent;
    background-color: #000080;
  }
}

/* Define colors for each status */
// .tab-new {
//   background-color: #4993e1;
// }

// .tab-rejected {
//   background-color: #d54453;
// }

// .tab-accepted {
//   background-color: #28a745;
// }

// .tab-interview {
//   background-color: #ffc107;
// }

// .tab-hired {
//   background-color: #f0ad4e;
// }

.tabsText {
  margin-right: 5px;
}

/* Styling for the count badge */
.count-badge {
  background-color: #fff;
  color: black;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8em;
  margin-left: 5px;
  display: inline-block;
  vertical-align: top;
}

.candidateList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 24px;
}

.candidateCard {
  display: flex;
  align-items: center;
  padding: 16px 16px 5px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-family: Gordita;

    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      color: #181818;
    }

    p {
      margin: 4px 0;
      color: #666;
    }
    .type {
      display: flex;
      flex-direction: row;
    }
    .role {
      color: #000080;
      margin-right: 10px;
      margin-top: 10px;
    }
    .location {
      font-size: 13px;
      font-weight: 300;
      justify-content: flex-end;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .buttonGreen {
      margin-right: 10px;
      border: 0 !important;
      border-radius: 9px !important;
      box-shadow: 0px 1px 6px rgba(48, 202, 97, 0.12) !important;
      font-weight: 400 !important;
      font-family: 'Gotham' !important;
      font-size: 18px !important;
      line-height: 20px !important;
      color: white !important;
      letter-spacing: 0.02em !important;
      background-color: #67b27f !important;
      cursor: pointer;
      margin-top: 20px;
      padding: 10px;
    }
    .buttonRed {
      margin-right: 10px;
      border: 0 !important;
      border-radius: 9px !important;
      box-shadow: 0px 1px 6px rgba(237, 95, 29, 0.12) !important;
      font-weight: 400 !important;
      font-family: 'Gotham' !important;
      font-size: 18px !important;
      line-height: 20px !important;
      color: white !important;
      letter-spacing: 0.02em !important;
      background-color: #d97a54 !important;
      cursor: pointer;
      margin-top: 20px;
      padding: 10px;
    }
    .buttonInvite {
      color: #67b27f !important;
      margin-right: 10px;
      border: 1px solid #67b27f !important;
      border-radius: 9px !important;
      box-shadow: 0px 1px 6px rgba(48, 202, 97, 0.12) !important;
      font-weight: 400 !important;
      font-family: 'Gotham' !important;
      font-size: 18px !important;
      line-height: 20px !important;
      letter-spacing: 0.02em !important;
      background-color: white !important;
      cursor: pointer;
      margin-top: 20px;
      padding: 10px;
    }
    .buttonHire {
      margin-right: 10px;
      border: 0 !important;
      border-radius: 9px !important;
      box-shadow: 0px 1px 6px rgba(48, 202, 97, 0.12) !important;
      font-weight: 400 !important;
      font-family: 'Gotham' !important;
      font-size: 18px !important;
      line-height: 20px !important;
      color: white !important;
      letter-spacing: 0.02em !important;
      background-color: #f38b08 !important;
      cursor: pointer;
      margin-top: 20px;
      padding: 10px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-left: 8px;
      border-radius: 50%;
      background: #e9e9e9;
      color: #333;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background: #dedede;
      }
    }
  }
}
.loadMoreContainer {
  background-color: #ffffff;
  text-align: right;
  padding: 0px 20px 20px;
  border-radius: 0 0 10px 10px;
}

/* ViewDetailsButtonImage.module.css */
.viewDetailsButtonImage {
  display: flex;
  position: relative;
  margin-top: 23px;
  display: inline-block;
  // cursor: pointer;
}

.tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: rgb(225, 217, 217);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width value to align it center */

  /* Optional: Adding some CSS animation can make it more interactive */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you hover over the viewDetailsButtonImage */
.viewDetailsButtonImage:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  input {
    width: 100%;
  }
}

.title {
  line-height: 27px;
  margin-top: 9px;
  margin-bottom: 5px;
  span {
    color: #787777;
  }
}
.link {
  width: 100%;
  border: 0.5px solid grey;
  border-radius: 9px;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
  padding: 0 10px;
  font-weight: 400;
  font-family: 'Gotham';
  font-size: 15px;
  line-height: 18px;
  color: #181818;
  letter-spacing: 0.02em;
  background-color: #f0f5f7;
  height: 40px;
  &:focus {
    background-color: white;
  }
}
.actionButton {
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1rem;
  width: 34px;
  height: 34px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;

  &:hover {
    background-color: #e8e8e8; // Or any other hover effect you'd like
    box-shadow: 3px 1px 1px #b3b9be;
  }
}
