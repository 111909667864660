.compProfPage {
  height: 100%;
  h1 {
    font-size: 26px;
    font-weight: bolder;
    color: #181818;
    line-height: 31px;
    font-family: Didact Gothic;
    padding-top: 0px;
  }
  .explain {
    font-size: 15px;
    color: #565758;
  }
  .card1 {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    h3 {
      font-size: 18px;
      font-weight: 500;
      font-style: Gordita;
    }

    .uploadCircle {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      border: 1px dashed #6f7071;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
.form {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  color: #565758;
  font-size: 15px;
  padding-bottom: 150px;
  label {
    font-family: Gordita;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding: 25px 0 10px 0;
    display: block;
  }
}
.contactDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  column-gap: 20px;
}
textarea {
  background-color: #f0f5f7;
  border-radius: 6px;
  border: none;
  padding: 15px;
}
.certificates {
  display: flex;
  > div {
    flex: 1;
  }
  button {
    top: 2px;
    right: 8px;
    background-color: black;
    color: #f0f5f7;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 16px;
  }
}

.saveBtn {
  margin-top: 50px;
  float: right;
}

// Styles for WYSIWYG
.toolbarClassName {
  background-color: #f0f5f7 !important;
  div {
    background-color: #f0f5f7;
    color: #333333;
  }
}

.wrapperClassName {
  background-color: #f0f5f7;
}

.wrapperClassNameError {
  background-color: #f0f5f7;
  border: 1px solid red;
}

.errorBorder {
  border: 1px solid red;
}

.editorClassName {
  background-color: #f0f5f7;
  padding: 25px;
  * {
    font-family: unset;
    font-style: unset;
    font-weight: unset;
  }
}
// End for Styles for WYSIWYG

.selectedCertificates {
  display: flex;
  border-radius: 9px;
  padding: 11px 0px;
  flex-wrap: wrap;

  .certificate {
    display: flex;
    padding: 10px 14px;
    margin: 5px 0;
    align-items: center;
    background: #dde7ff;
    border-radius: 9px;
    margin-right: 1vh;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #181818;
    margin-top: 2px;

    p {
      flex: 1;
      margin: 0 10px 0 0;
    }
    img {
      cursor: pointer;
    }
  }
  .clearEmpty {
    flex: 1;
  }
}
.errorMessage {
  color: red;
}

.phoneInputClass {
  height: 50px !important;
  width: 100% !important;
  border: 0 !important;
  border-radius: 9px !important;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12) !important;
  font-weight: 400 !important;
  font-family: 'Gotham' !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #181818 !important;
  letter-spacing: 0.02em !important;
  background-color: #f0f5f7 !important;
  border: 0.5px solid grey !important;
}

.phoneButtonClass {
  border: 0 !important;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12) !important;
}

.social {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 20px;
  .title {
    padding-top: 15px;
  }
}
.networks {
  font-size: 18px;
  // font-family: Gordita;
  line-height: 24.3px;
  letter-spacing: 2%;
  margin-bottom: 0;
  margin-top: 25px;
  font-family: 'Gotham';
}
.upload {
  display: flex;
  justify-content: center;
}
.croppedLogoBlock {
  display: flex;
  flex-direction: column;
  img {
    width: 150px;
    margin-bottom: 10px;
    border-radius: 50%;
  }
}
.buttonLogo {
  border: 0 !important;
  border-radius: 9px !important;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12) !important;
  font-weight: 400 !important;
  font-family: 'Gotham' !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: white !important;
  letter-spacing: 0.02em !important;
  background-color: #109fd7 !important;
  // background-color: #0711ce;
  cursor: pointer;
  margin-top: 20px;
  padding: 10px;
}
.suggest {
  font-weight: 300;
  color: #9c9c9c;
  display: flex;
  justify-content: center;
  margin-top: 22px;
}
.success_window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .success_message {
    font-weight: 500;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    text-align: center; /* Ensure the text is centered */
    line-height: 24px;
  }

  button {
    margin-top: 20px; /* Add some space between the message and the button */
  }
}
