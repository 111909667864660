.title {
  font-size: 26px;
  font-weight: bolder;
  color: #181818;
  line-height: 31px;
  font-family: Didact Gothic;
  padding-top: 0px;
}
p {
  margin-top: 0;
}
.manage {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: white;
  padding: 15px 20px;
  border-radius: 9px;
  height: 156px;
  .top {
    font-family: Gordita;
    font-size: 22px;
    font-weight: 500;
    line-height: 31.35px;
    text-align: left;
  }
  .main {
    display: flex;
    flex-direction: row;
    .green {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      background-color: rgb(222, 251, 222);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .total {
    padding: 3px;
    padding-left: 10px;

    .text {
      font-family: Gordita;
      font-size: 15px;
      font-weight: 400;
      line-height: 21.37px;
      text-align: left;
      color: #393939d6;
      margin-bottom: 0;
    }
    .number {
      font-family: Gordita;
      font-size: 35px;
      font-weight: 700;
      line-height: 47.25px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }
}
