.card {
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  padding: 10px;
  width: 100%;
  background-color: #dde7ff;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
}
.cancelButton {
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cancelButton:hover {
  transform: scale(1.1);
}
.cancelButton:active {
  transform: scale(0.95);
}

.add {
  display: flex;
  justify-content: right;
  .blackButton {
    width: 200px;
    color: white;
    font-weight: bolder;
    background-color: #181818;
    border-radius: 7px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
