.uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .uploadCircle {
    box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12);
    border: 1px dashed lightgrey;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #dee9f0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    text-align: center;
    color: grey;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
canvas {
  display: flex;
  align-items: center;
}
.scaleRight {
  align-self: self-end;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 25px 0;
  input {
    height: 30px !important;
    width: 70px !important;
    border: 0 !important;
    border-radius: 9px !important;
    box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12) !important;
    font-weight: 400 !important;
    font-family: 'Gotham' !important;
    font-size: 15px !important;
    line-height: 18px !important;
    color: #181818 !important;
    letter-spacing: 0.02em !important;
    background-color: #f0f5f7 !important;
    padding-left: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 4px !important;
    border: 0.5px solid grey !important;
  }
}
.uploadEdit {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonLogoCnt {
  display: flex;
  justify-content: right;
}
.buttonLogo {
  border: 0 !important;
  border-radius: 9px !important;
  box-shadow: 0px 1px 6px rgba(64, 79, 104, 0.12) !important;
  font-weight: 400 !important;
  font-family: 'Gotham' !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: white !important;
  letter-spacing: 0.02em !important;
  background-color: #109fd7 !important;
  // background-color: #0711ce;
  cursor: pointer;
  margin-top: 20px;
  padding: 10px;
}
