.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 12px;
  .right {
    line-height: 30px;
    font-family: Gordita;
    background-color: #f5f7fc;
    margin-top: 40px;
    margin-right: 80px;
    border-radius: 7px;
    .overviewJob {
      width: 380px;
      height: 450px;
      background: #f5f7fc;
      padding: 41px 34px 41px 27px;
      display: flex;
      flex-direction: column;
      row-gap: 23px;
      border-radius: 7px;
    }
    .overviewJobMap {
      background: #f5f7fc;
      height: 370px;
      width: 380px;
      margin-top: 15px;
      padding: 41px 34px 41px 27px;
      img {
        margin-top: 25px;
      }
    }
  }
  .center {
    width: 70%;
    padding-right: 25px;
    margin: 30px 20px 30px 80px;
    .paragraph {
      margin-top: 20px;
      margin-bottom: 20px;
      .title {
        font-family: Gordita;
        font-size: 18px;
        font-weight: 500px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .text {
        font-family: Gordita;
        font-style: normal;
        font-size: 16px;
        align-items: left;
        font-weight: 300;
        line-height: 23px;
        li {
          font-family: Gordita;
          font-style: normal;
          font-size: 16px;
          align-items: left;
          font-weight: 300;
          line-height: 23px;
        }
      }
    }
    .shareButtons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      a {
        margin: 10px;
      }
      div {
        margin: 10px;
        font-family: Gordita;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.mainLastStep {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  .background {
    background-color: #f5f7fc;
  }
}
