.icon {
  border-radius: 50%;
  text-align: center;
  padding: 4% 0% 2% 3%;
}

.md-12 {
  width: 12px;
  height: 12px;
}
.md-15 {
  width: 15px;
  height: 15px;
}
.md-17 {
  width: 17px;
  height: 17px;
}
.md-18 {
  width: 18px;
  height: 18px;
}
.md-20 {
  width: 20px;
  height: 20px;
}
.md-24 {
  width: 24px;
  height: 24px;
}
.md-30 {
  width: 30px;
  height: 30px;
}
.md-40 {
  width: 40px;
  height: 40px;
}
.md-48 {
  width: 48px;
  height: 48px;
}
.md-100 {
  width: 100px;
  height: 100px;
}
